<template>
  <b-card
    :header="$i18n.t('orders.orders')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <div class="d-flex align-items-start flex-column">
      <div class="d-flex align-items-end mb-2 filters">
        <FiltersAndSearch
            :id.sync="id"
            :searchKey.sync="searchKey"
            :paymentStatus.sync="orderPaymentStatus"
            :paymentStatusFields="paymentStatus"
            :disableActiveOnly.sync="activeOnly"
            :startDate.sync="startDate"
            :endDate.sync="endDate"
        />
        <b-button
            title="Refetch data"
            variant="primary"
            class="mr-1 p-0-8"
            @click="handleFetchOrders()"
        >
          <feather-icon icon="SearchIcon"></feather-icon>
        </b-button>
        <ExportFromTo
            title="Export to excel"
            :exportHandler="exportOrdersHandler"
            :filtersVisible="false"
            :iconOnly="true"
        />

      </div>
    </div>

    <!-- end export -->
    <b-table
      sticky-header
      bordered
      hover
      responsive
      class="shadow-sm rounded products-table orders-table"
      thead-tr-class="order-main-thead"
      :items="ordersList"
      :busy="isTableBusy"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      @row-clicked="onRowClicked"
    >
      <template #cell(manualTransfer)="data">
        <div class="d-flex align-items-center justify-content-center">
          <b-button v-if="data.item.splitShouldBePaid || data.item.referralCodeName"
                    :variant="`outline-${data.item.splitShouldBePaid ? 'danger' : 'success'}`"
                    size="sm"
                    @click="openManualTransferModal(data.item.id)"
          >
            <feather-icon v-if="data.item.splitShouldBePaid" icon="AlertTriangleIcon"></feather-icon>
            <feather-icon v-if="!data.item.splitShouldBePaid && data.item.referralCodeName" icon="CheckIcon"></feather-icon>
          </b-button>
        </div>
      </template>
      <template #cell(refund)="data">
        <div class="d-flex align-items-center justify-content-center">
          <b-button
          variant="outline-danger"
          size="sm"
          :disabled="data.item.refundedAmount >= data.item.revenue"
          @click="showRefundModal(data.item)"
        >
          Refund
        </b-button>
        </div>
      </template>
    </b-table>
    <Pagination
      :fetch-data="handleFetchOrders"
      :skip.sync="skip"
      :total-rows="totalRows"
      :take.sync="take"
    />
    <b-modal
        id="refund-modal"
        ref="refund-modal"
        scrollable
        :title="$i18n.t('orders.refund')"
        @ok="moneyRefund()"
        @hide="hideRefundModal"
    >
      <b-form-group :label="$t('orders.refundAmount')">
        <b-form-input
            v-model="refundAmount"
            type="number"
            :placeholder="$t('orders.refundAmount')"
        />
      </b-form-group>
    </b-modal>
    <b-modal
        id="manual-transfer-modal"
        ref="manual-transfer-modal"
        scrollable
        size="xl"
        :title="$i18n.t('orders.refund')"
        @hide="hideManualTransferModal"
    >
      <b-table
          sticky-header
          bordered
          hover
          responsive
          class="shadow-sm rounded products-table orders-table"
          thead-tr-class="order-main-thead"
          :items="selectedOrderSplits"
          :busy="isTableBusy"
          :fields="splitFields"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          @row-clicked="onRowClicked"
      >
        <template #cell(payment)="data">
          <validation-observer ref="manualTransferValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group>
                <validation-provider #default="{ errors }" name="manualTransfer" rules="required">
                  <div class="d-flex align-items-center">
                    <b-form-input
                        v-model="data.item.amount"
                        type="number"
                        :placeholder="$t('orders.manualTransfer')"
                    />
                    <b-button
                        variant="flat-secondary"
                        size="sm"
                        @click="updateSplitAmount(data.item)"
                    >
                      <feather-icon icon="SendIcon"></feather-icon>
                    </b-button>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </template>
        <template #cell(paid)="data">
          <p v-if="data.item.paid">{{ $t('orders.paid') }}</p>
          <p v-else>{{ $t('orders.notPaid') }}</p>
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>

<script>
import { BTable, BCard, BButton, BFormInput, BFormGroup, BForm } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Pagination from "@/views/components/Pagination/Pagination.vue";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ExportFromTo from "../components/ExportFromTo/ExportFromTo.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BCard,
    Pagination,
    ExportFromTo,
    FiltersAndSearch,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      ordersList: [],
      totalRows: 0,
      skip: 0,
      from: null,
      to: null,
      take: 10,
      searchKey: "",
      activeOnly: true,
      sortOrder: 2,
      status: "",
      orderPaymentStatus: "",
      id: "",
      endDate: new Date().toISOString().split("T")[0],
      startDate: new Date().toISOString().split("T")[0],
      priceSort: false,
      manualTransferAmount: 0,
      refundAmount: 0,
      selectedOrder: null,
      selectedOrderId: null,
      selectedOrderSplits: null,
    };
  },

  computed: {
    ...mapState("ordersModule", ["orders", "isTableBusy"]),
    orderStatus() {
      return {
        1: this.$i18n.t("orders.pending"),
        2: this.$i18n.t("orders.sent"),
        3: this.$i18n.t("orders.seenByReceiver"),
        4: this.$i18n.t("orders.rejected"),
        5: this.$i18n.t("orders.scheduled"),
        6: this.$i18n.t("orders.finished"),
      };
    },
    paymentStatus() {
      return {
        // 0: this.$i18n.t("orders.waiting"),
        1: this.$i18n.t("orders.failed"),
        2: this.$i18n.t("orders.paid"),
        3: this.$i18n.t("orders.refunded"),
        4: this.$i18n.t("orders.partiallyRefunded"),
      };
    },
    fields() {
      return [
        {
          label: "order-id",
          key: "id",
        },
        {
          label: this.$i18n.t("orders.startDate"),
          key: "createdAt",
          sortable: false,
        },
        {
          label: this.$i18n.t("orders.endDate"),
          key: "endDate",
          sortable: false,
        },
        {
          label: this.$i18n.t("orders.giver"),
          key: "giverFullName",
          formatter: (value, key, item) =>
            `${item.giverId} - ${item.giverFullName}`,
        },
        {
          label: this.$i18n.t("orders.receiver"),
          key: "receiverFullName",
          formatter: (value, key, item) =>
            `${item.receiverId} - ${item.receiverFullName}`,
        },
        {
          label: this.$i18n.t("orders.orderPrice"),
          key: "orderPrice",
          sortable: false,
          formatter: (value) => `${value || ''}  ₾`,
        },
        {
          label: this.$i18n.t("orders.promoCodeName"),
          key: "referralCodeName",
          sortable: false,
        },
        {
          label: this.$i18n.t("orders.revenue"),
          key: "revenue",
          sortable: false,
          formatter: (value) => `${value}  ₾`,
        },
        {
          label: this.$i18n.t("orders.paymentStatus"),
          key: "paymentStatus",
          sortable: false,
          formatter: (value) => `${this.paymentStatus[value]}`,
        },
        {
          label: this.$i18n.t("orders.giftStatus"),
          key: "status",
          sortable: false,
          formatter: (value) => `${this.orderStatus[value]}`,
        },
        {
          label: this.$i18n.t("orders.giftedCustomerEmotyFeedback"),
          key: "giftedCustomerEmotyFeedback",
        },
        {
          label: this.$i18n.t("orders.manualTransfer"),
          key: "manualTransfer",
        },
        {
          label: this.$i18n.t("orders.refund"),
          key: "refund",
        },
        {
          label: this.$i18n.t("orders.refundedAmount"),
          key: "refundedAmount",
        },
        {
          label: this.$i18n.t("orders.feedback"),
          key: "giftedCustomerEmotyFeedback",
        },
      ];
    },
    splitFields() {
      return [
        {
          label: "ID",
          key: "id",
        },
        {
          label: this.$i18n.t("orders.iban"),
          key: "iban",
        },
        {
          label: this.$i18n.t("orders.isPaid"),
          key: "paid",
        },
        {
          label: this.$i18n.t("orders.payment"),
          key: "payment",
        },
        {
          label: this.$i18n.t("orders.vendor"),
          key: "vendor",
        },
        {
          label: this.$i18n.t("orders.emotyAmount"),
          key: "emotyPaidAmount",
        },
        {
          label: this.$i18n.t("orders.originalAmount"),
          key: "originalAmount",
        },
        {
          label: this.$i18n.t("orders.promoCodeAmount"),
          key: "afterPromoCodeAmount",
        },
      ];
    },
  },
  watch: {
    orders(newVal) {
      console.log(newVal);
      this.totalRows = newVal.count;
      this.ordersList = newVal.orders;
      this.skip = newVal.skip;
      this.take = newVal.take;
    },
    searchKey() {
      this.handleFetchOrders();
    },
    activeOnly() {
      this.handleFetchOrders();
    },
    status() {
      this.handleFetchOrders();
    },
    orderPaymentStatus() {
      this.handleFetchOrders();
    },
    id() {
      this.handleFetchOrders();
    },
    startDate() {
      this.handleFetchOrders();
    },
    endDate() {
      this.handleFetchOrders();
    },
    take() {
      this.handleFetchOrders();
    },
    priceSort() {
      this.handleFetchOrders();
    },
    sortOrder() {
      this.handleFetchOrders();
    },
  },
  created() {
    this.handleFetchOrders();
  },
  methods: {
    ...mapActions("ordersModule", [
      "getOrders",
      "getOrderById",
      "exportOrders",
      "refundMoneyByOrderId",
      "getOrderSplits",
      "manualTransferPerSplit"
    ]),
    moneyRefund() {
      this.refundMoneyByOrderId({ id: this.selectedOrder.id, amount: this.refundAmount }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Money refunded successfully",
            icon: "RotateCcwIcon",
            variant: "success",
          },
        });
      })
    },
    updateSplitAmount(split) {
      this.manualTransferPerSplit({ orderId: this.selectedOrderId, amount: split.amount, splitId: split.id })
        .then(() => {
          this.openManualTransferModal(this.selectedOrderId);
          this.handleFetchOrders();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Manual transfer saved successfully",
              icon: "RotateCcwIcon",
              variant: "success",
            },
          });
      })
    },
    openManualTransferModal(id) {
      this.selectedOrderId = id
      this.getOrderSplits({ id }).then((res) => {
        this.selectedOrderSplits = res.splits;
        // eslint-disable-next-line no-param-reassign,no-return-assign
        this.selectedOrderSplits.map(el => el.amount = (el.originalAmount - el.afterPromoCodeAmount).toFixed(2));
        this.showManualTransferModal()
      })
    },
    showManualTransferModal(order) {
      this.$refs["manual-transfer-modal"].show();
    },
    hideManualTransferModal() {
      this.handleFetchOrders();
      this.selectedOrderSplits = null;
      this.selectedOrderId = null
      this.manualTransferAmount = 0;
      this.$refs["manual-transfer-modal"].hide();
    },
    showRefundModal(order) {
      this.selectedOrder = order;
      this.$refs["refund-modal"].show();
    },
    hideRefundModal() {
      this.selectedOrder = null;
      this.refundAmount = 0;
      this.$refs["refund-modal"].hide();
    },
    toast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    async handleFetchOrders() {
      this.getOrders({
        skip: this.skip,
        take: this.take,
        searchKey: this.searchKey,
        activeOnly: this.activeOnly,
        sortOrder: this.sortOrder,
        status: this.status,
        paymentStatus: this.orderPaymentStatus,
        id: this.id,
        startDate: this.startDate,
        endDate: this.endDate,
        priceSort: this.priceSort,
      });
    },
    async exportOrdersHandler(from, to) {
      // const payload = { from, to };
      // const payload = { from: this.startDate, to: this.endDate };
      const payload = {
        skip: this.skip,
        take: this.take,
        searchKey: this.searchKey,
        activeOnly: this.activeOnly,
        sortOrder: this.sortOrder,
        status: this.status,
        paymentStatus: this.orderPaymentStatus,
        id: this.id,
        startDate: this.startDate,
        endDate: this.endDate,
        priceSort: this.priceSort,
      }
      try {
        console.log("payload", payload)
        const response = await this.exportOrders(payload);
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Orders-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    onRowClicked(item) {
      window.open(`/orders/${item.id}`, "_blank")
    },
  },
};
</script>

<style lang="scss">
.custom-search {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: flex-start;
  }
}

.filters {
  .form-group {
    margin-bottom: 0 !important;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.p-0-8 {
  padding: 0.8rem
}

.pointer {
  cursor: pointer;
}

.filters {
  label.form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }
}
</style>
